
import { handleQueryResolve, buildMapToField } from "../utils";

const uniqBy = require('lodash/uniqBy');

export default function(PatientID){
    // classic JS with hoist and scope
    // FIXME break those function to their own modules and call them with this[query]
    const _vm = this;

    return Promise.all([
        queryPatientProfile(PatientID),
        queryPharmacyInfo(),
        queryPatientMedication(PatientID),
        queryPatientDoctor(PatientID)
    ]).then(patientRecords => {
        return patientRecords.reduce((patientData, patientRecord) => {
            return {
                ...patientData,
                ...patientRecord
            }
        }, {});
    });

    ///
    function queryPatientMedication(PatientID){
        // extract Unqiue DINs of Patient
        return _vm.query(`
            SELECT
            rx.DIN
            FROM
            Fillware.dbo.Rx as rx
            WHERE
            rx.PatientId = @PatientID AND rx.DIN IS NOT NULL
            GROUP BY rx.DIN
        `, [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID // to support like
        }])
        .then(handleQueryResolve)
        .then((results) => {
            // get records of each PatientDIN
            const DINS_LIST = Object.values(results).reduce((cur, record) => {
                if (record.DIN && !isNaN(record.DIN)) {
                    cur.push(record.DIN);
                }
                return cur;
            }, []);

            // if an empty Array, don't proceed and handle it as a promise error
            if (DINS_LIST.length) {
                return DINS_LIST
            }

            return null;
        })
        .then((listOfPatientDINs) => {
            // console.log(listOfPatientDINs);
            if (listOfPatientDINs && listOfPatientDINs.length) {
                return _vm.query(`
                SELECT
                CONCAT(
                    drug.GenericName,
                    '-',
                    drug.Strength,
                    ',',
                    'EZETIMIBE-10MG'
                ) AS Medication,
                    rx.DIN,
                    rx.RxNumber,
                    rx.RxDate,
                    drug.BrandName,
                    drug.Strength,
                    drug.GenericName,
                    drug.Form,
                    drug.Schedule,
                    Indecation.Description,
                    rx.Days,
                    rx.SIGFULL,
                    rx.QtyDispense,
                    rx.QtyRemain,
                    rx.Hold,
                    rx.Status,
                    DosR.Brk,
                    DosR.Noon,
                    DosR.Supp,
                    DosR.Bed,
                    DosR.IsOTC,
                    DosR.IsInactive,
                    DosR.ModifiedOn,
                    DosR.Modification,
                    DosR.PrevRxNumber
                FROM
                    Fillware.dbo.DrugRoot as drug
                INNER JOIN
                    Fillware.dbo.Rx as rx ON drug.DIN = rx.DIN AND rx.PatientID = @PatientID AND rx.DIN IN (${listOfPatientDINs.toString()})
                LEFT JOIN
                    Fillware.dbo.DosR ON DosR.RxNumber = rx.RxNumber
                LEFT JOIN
                    Fillware.dbo.DrugCategories as Indecation ON drug.Therapeutic = Indecation.Code
                WHERE rx.RxDate > DATEADD(year, -1, GetDate()) AND ( rx.Status <> 'D' OR rx.Status <> 'I' OR rx.Status is null )
                AND (CONCAT(drug.GenericName, '-',drug.Strength) IN (
                    'ROSUVASTATIN CALCIUM-40MG',
                    'ATORVASTATIN CALCIUM-80MG',
                    'FLUVASTATIN-40MG',
                    'LOVASTATIN-40MG',
                    'PRAVASTATIN-40MG',
                    'SIMVASTATIN-80MG'
                ) OR CONCAT(drug.GenericName, '-',drug.Strength) IN (
                    'EZETIMIBE-10MG'
                ) )
                ORDER BY rx.RxDate DESC
                    `, 
                    [{
                        param: 'PatientID',
                        type: 'sql.Int',
                        value: PatientID // to support like
                    }]
                );
            }
            // empty medications
            return {
                medications: []
            };
        },
        // handle empty DIN LIST 
        () => {
            return {
                medications: []
            };
        })
        .then(handleQueryResolve)
        .then((medicationResults) => {
            let listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map((record, index) => {
                return {
                    // [`ID`]: key,
                    [`DIN`]: record['DIN'],
                    [`RxNumber`]: record['RxNumber'],
                    [`RxDate`]: record['RxDate'],
                    [`QtyDispense`]: record['QtyDispense'],
                    [`QtyRemain`]: record['QtyRemain'],
                    [`Days`]: record['Days'],
                    [`RX?`]: record['Schedule'] == '1' ? 'Yes' : '',
                    [`NHP?`]: '',
                    [`GenericName`]: record['GenericName'],
                    [`OTC?`]: record['IsOTC'] == '1' ? 'Yes' : '',
                    [`onHold`]: record['Hold'] == '1' ? 'Yes' : '',
                    [`MED`]: record['BrandName'],
                    [`MED STR`]: record['Strength'],
                    [`FORM`]: record['Form'],
                    [`SIG`]: record['SIGFULL'],
                    [`INDICATION`]: record['Description'] ? [record['Description'].toUpperCase()] : '',
                    [`MED AM QTY`]: record['Brk'],
                    [`MED LUNCH QTY`]: record['Noon'],
                    [`MED DINNER QTY`]: record['Supp'],
                    [`MED BEDTIME QTY`]: record['Bed'],
                    [`MED OTHER QTY`]: '',
                    [`ADHERENCE YES`]: '',
                    [`ADHERENCE NO`]: '',
                    [`PATIENT COMMENT`]: '',
                    [`MED PHARMACIST NOTES`]: '',
                    [`COMMENTS FOR MEDSCHECK RECORD`]: ''
                }
            });

            return {
                medications: uniqBy(listOfNonRepeatedMeds, med => [med["GenericName"], med["MED STR"]].join())
            };
        });
    }


    // TODO OCP Pharmacist's # is under "USERS"
    function queryPharmacyInfo(){
        return _vm.query(`
            SELECT
            DISTINCT sParameterName,
            sParameterValue
            FROM 
            Fillware.dbo.SystemParameters
            WHERE
            ( 
                sParameterName = 'PharmacyName' 
                OR
                sParameterName = 'Address'
                OR
                sParameterName = 'City'
                OR
                sParameterName = 'Province'
                OR
                sParameterName = 'Postal'
                OR
                sParameterName = 'Fax'
                OR
                sParameterName = 'Phone'
            ) 
            AND 
            ( 
                sParameterValue IS NOT NULL
                OR
                sParameterValue != ''
            )
        `, [])
        .then(handleQueryResolve)
        .then((recordset) => {

            const MapValuesTo = {
                PharmacyName: 'PharmacyName',
                Address: 'PharmacyAddress',
                City: 'PharmacyCity',
                Province: 'PharmacyProvince',
                Postal: 'PharmacyPostal',
                Fax: 'PharmacyFax',
                Phone: 'PharmacyPhone'
            };

            const whiteList = Object.keys(MapValuesTo);

            let pharmacyInfo = recordset.reduce((cur, row) => {

                if (whiteList.indexOf(row['sParameterName']) == -1 || !row['sParameterValue']) {
                    return cur;
                }

                return {
                    ...cur,
                    [MapValuesTo[row['sParameterName']]]: row['sParameterValue']
                }
            }, {});

            return buildMapToField(pharmacyInfo);
        });
    }

    function queryPatientProfile(PatientID) {
        return _vm.query(`
            SELECT
            patient.PatientID,
            patient.FirstName,
            patient.LastName,
            null as FamilyDoc,
            patient.OHIP,
            patient.Address,
            patient.City,
            patient.Province,
            patient.Postal,
            patient.EMail,
            patient.HomePhone,
            patient.MobilePhone,
            patient.Allergies,
            patient.DOB,
            patient.Gender,
            patient.LastVisit,
            patient.MedsCheckReminder
            FROM
            Fillware.dbo.Patient as patient
            WHERE
            patient.PatientID = @PatientID
            `, [{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID
            }]
        )
        .then(handleQueryResolve)
        .then((recordset) => {
            return buildMapToField(recordset[0]);
        });
    }

    // Primary Provider is the top doctor
    function queryPatientDoctor(PatientID){
        return _vm.query(`
            SELECT
                doctor.DoctorID,
                doctor.FirstName as DoctorFirstName,
                doctor.LastName as DoctorLastName,
                doctor.EMail as DoctorEmail,
                doctor.ID as CPSO,
                DoctorPhone.Phone as DoctorPhone,
                DoctorFax.Phone as DoctorFax,
                DoctorAddress.Address as DoctorAddress,
                DoctorAddress.City as DoctorCity,
                DoctorAddress.Province as DoctorProvince,
                DoctorAddress.Postal as DoctorPostal,
                COUNT(doctor.DoctorID) as RXperProvider
            FROM
                Fillware.dbo.Rx as rx
            INNER JOIN
                Fillware.dbo.Doctor as doctor ON doctor.DoctorID = rx.DoctorID
            INNER JOIN
                Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
            INNER JOIN
                Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
            LEFT JOIN
                Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
            LEFT JOIN
                Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
            WHERE
                rx.RxDate > DATEADD(year, -1, GetDate()) AND
                rx.PatientID = @PatientID
            GROUP BY
                doctor.DoctorID,
                doctor.FirstName,
                doctor.LastName,
                doctor.EMail,
                doctor.ID,
                DoctorFax.Phone,
                DoctorPhone.Phone,
                DoctorAddress.Address,
                DoctorAddress.City,
                DoctorAddress.Province,
                DoctorAddress.Postal
            ORDER BY RXperProvider DESC
        `, [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID
        }])
        .then(handleQueryResolve)
        .then((recordset) => {
            if (recordset.length) {
                // reverted back to the old code due that it breaks
                // primary Telephone and Fax
                return buildMapToField(recordset[0]);
            }
            return {};
        });
    }
}